<template>
  <div class="chart-basic-container">
    <div class="chart-content">
      <Row>
        <Col span="20">
        <div>不同类型会员日建档量</div>
        <div class="chart" ref="basicData"></div>
        </Col>
        <Col span="4">
        <div class="radio-mine">
          <Radio-group v-model="chooseOne.grade" vertical @on-change="changePerson">
            <Radio label="全部会员"></Radio>
            <Radio :label="v.label"  v-for="v in memberType" :key="v.label"></Radio>
          </Radio-group>
        </div>
        </Col>
      </Row>
    </div>
    <div class="chart-content">
      <Row>
        <Col span="20">
        <div>不同类型会员年龄比例</div>
        <div class="chart" ref="ageData"></div>
        </Col>
        <Col span="4">
        <div class="radio-mine">
          <Radio-group v-model="chooseTwo.grade" vertical @on-change="changeAge">
            <Radio label="全部会员"></Radio>
            <Radio :label="v.label"  v-for="v in memberType" :key="v.label"></Radio>
          </Radio-group>
        </div>
        </Col>
      </Row>
    </div>
    <div class="chart-content">
      <div>不同类型会员男女比例</div>
      <Row :gutter="16">
        <Col span="6">
        <div class="mycard">
          <div class="mycard-title bg10">全部会员</div>
          <Row type="flex" justify="center" align="middle" style="height: 162px;">
            <Col span="24">
            <div class="chartdetail" ref="sexData"></div>
            </Col>
          </Row>
        </div>

        </Col>
        <Col span="6">
        <div class="mycard">
          <div class="mycard-title bg11">卡系会员</div>
          <Row type="flex" justify="center" align="middle" style="height: 162px;">
            <Col span="24">
            <div class="chartdetail" ref="sexBasicData"></div>
            </Col>
          </Row>
        </div>

        </Col>
        <Col span="6">
        <div class="mycard">
          <div class="mycard-title bg7">老会员</div>
          <Row type="flex" justify="center" align="middle" style="height: 162px;">
            <Col span="24">
            <div class="chartdetail" ref="sexCarData"></div>
            </Col>
          </Row>
        </div>

        </Col>
        <Col span="6">
        <div class="mycard">
          <div class="mycard-title bg9">在服会员</div>
          <Row type="flex" justify="center" align="middle" style="height: 162px;">
            <Col span="24">
            <div class="chartdetail" ref="sexLineData"></div>
            </Col>
          </Row>
        </div>

        </Col>

      </Row>

    </div>

    <div class="chart-content">
      <Row>
        <Col span="20">
        <div>不同类型会员疾病统计</div>
        <div class="chart" ref="sickData"></div>
        </Col>
        <Col span="4">
        <div class="radio-mine">
          <Radio-group v-model="chooseThree.grade" vertical @on-change="changeSick">
            <Radio label="全部会员"></Radio>
            <Radio :label="v.label"  v-for="v in memberType" :key="v.label"></Radio>
          </Radio-group>
        </div>
        </Col>
      </Row>
    </div>

    <div class="chart-content">
      <div>不同类型会员与子女同住比例</div>
      <Row :gutter="16">
        <Col span="6">
        <div class="mycard">
          <div class="mycard-title bg10">全部会员</div>
          <Row type="flex" justify="center" align="middle" style="height: 162px;">
            <Col span="24">
            <div class="chartdetail" ref="conhabitData"></div>
            </Col>
          </Row>
        </div>

        </Col>
        <Col span="6">
        <div class="mycard">
          <div class="mycard-title bg11">卡系会员</div>
          <Row type="flex" justify="center" align="middle" style="height: 162px;">
            <Col span="24">
            <div class="chartdetail" ref="conhabitBasicData"></div>
            </Col>
          </Row>
        </div>

        </Col>
        <Col span="6">
        <div class="mycard">
          <div class="mycard-title bg7">老会员</div>
          <Row type="flex" justify="center" align="middle" style="height: 162px;">
            <Col span="24">
            <div class="chartdetail" ref="conhabitCarData"></div>
            </Col>
          </Row>
        </div>

        </Col>
        <Col span="6">
        <div class="mycard">
          <div class="mycard-title bg9">在服会员</div>
          <Row type="flex" justify="center" align="middle" style="height: 162px;">
            <Col span="24">
            <div class="chartdetail" ref="conhabitLineData"></div>
            </Col>
          </Row>
        </div>

        </Col>

      </Row>

    </div>

    <div class="chart-content">
      <Row>
        <Col span="20">
        <div>不同类型会员文化程度统计</div>
        <div class="chart" ref="eduData"></div>
        </Col>
        <Col span="4">
        <div class="radio-mine">
          <Radio-group v-model="chooseFour.grade" vertical @on-change="changeEdu">
            <Radio label="全部会员"></Radio>
            <Radio :label="v.label"  v-for="v in memberType" :key="v.label"></Radio>
          </Radio-group>
        </div>
        </Col>
      </Row>
    </div>

    <div class="chart-content">
      <div>不同类型会员婚姻状况统计</div>
      <Row :gutter="16">
        <Col span="6">
        <div class="mycard">
          <div class="mycard-title bg10">全部会员</div>
          <Row type="flex" justify="center" align="middle" style="height: 162px;">
            <Col span="24">
            <div class="chartdetail" ref="meData"></div>
            </Col>
          </Row>
        </div>

        </Col>
        <Col span="6">
        <div class="mycard">
          <div class="mycard-title bg11">卡系会员</div>
          <Row type="flex" justify="center" align="middle" style="height: 162px;">
            <Col span="24">
            <div class="chartdetail" ref="meBasicData"></div>
            </Col>
          </Row>
        </div>

        </Col>
        <Col span="6">
        <div class="mycard">
          <div class="mycard-title bg7">老会员</div>
          <Row type="flex" justify="center" align="middle" style="height: 162px;">
            <Col span="24">
            <div class="chartdetail" ref="meCarData"></div>
            </Col>
          </Row>
        </div>

        </Col>
        <Col span="6">
        <div class="mycard">
          <div class="mycard-title bg9">在服会员</div>
          <Row type="flex" justify="center" align="middle" style="height: 162px;">
            <Col span="24">
            <div class="chartdetail" ref="meLineData"></div>
            </Col>
          </Row>
        </div>

        </Col>

      </Row>
    </div>
    <div class="chart-content">
      <div>BMI</div>
      <Row>
        <Col span="4" style="text-align: center;">
        <v-circle :size="130" :trail-width="12" :stroke-width="12" :percent="light/all*100" stroke-linecap="" stroke-color="#f1ce7e">
          <span class="big-percent color7">{{(light/all*100).toFixed(2)}}%</span>
          <div class="bmi-num">人数：{{light}}</div>
        </v-circle>
        <div>
          <span class="bmi-title bg7">过轻</span>低于18.5</div>
        </Col>
        <Col span="1">&nbsp;</Col>
        <Col span="4">
        <v-circle :size="130" :trail-width="12" :stroke-width="12" :percent="normal/all*100" stroke-linecap="" stroke-color="#f1ce7e">
          <span class="big-percent color7">{{(normal/all*100).toFixed(2)}}%</span>
          <div class="bmi-num">人数：{{normal}}</div>
        </v-circle>
        <div>
          <span class="bmi-title bg2">正常</span>18.5~23.9</div>
        </Col>
        <Col span="1">&nbsp;</Col>
        <Col span="4">
        <v-circle :size="130" :trail-width="12" :stroke-width="12" :percent="weight/all*100" stroke-linecap="" stroke-color="#f1ce7e">
          <span class="big-percent color7">{{(weight/all*100).toFixed(2)}}%</span>
          <div class="bmi-num">人数：{{weight}}</div>
        </v-circle>
        <div>
          <span class="bmi-title bg7">过重</span>24~27.9</div>
        </Col>
        <Col span="1">&nbsp;</Col>
        <Col span="4">
        <v-circle :size="130" :trail-width="12" :stroke-width="12" :percent="fat/all*100" stroke-linecap="" stroke-color="#f1ce7e">
          <span class="big-percent color7">{{(fat/all*100).toFixed(2)}}%</span>
          <div class="bmi-num">人数：{{fat}}</div>
        </v-circle>
        <div>
          <span class="bmi-title bg7">肥胖</span>28~31.9</div>
        </Col>
        <Col span="1">&nbsp;</Col>
        <Col span="4">
        <v-circle :size="130" :trail-width="12" :stroke-width="12" :percent="very_fat/all*100" stroke-linecap="" stroke-color="#f1ce7e">
          <span class="big-percent color8">{{(very_fat/all*100).toFixed(2)}}%</span>
          <div class="bmi-num">人数：{{very_fat}}</div>
        </v-circle>
        <div>
          <span class="bmi-title bg8">非常肥胖</span>高于32</div>
        </Col>
        <div class="tac">经统计, 用户群体中共有{{weight+fat+very_fat}}位用户BMI>24,属于过重范畴, 其中{{fat+very_fat}}位用户BMI>28,达到肥胖标准， 需进行饮食调节或加强锻炼进行调整，其中共有{{light}}位用户BMI低于18.5,属于过轻范畴
        </div>

      </Row>
    </div>

  </div>
</template>

<script type="text/ecmascript-6">
import echarts from 'echarts';
import chartService from '@/services/chartService';
import MemberService from '@/services/memberService';
import vCircle from 'components/circle/circle';
export default {
	components: {
		'v-circle': vCircle,
	},
	data() {
		return {
			chooseOne: {
				grade: '全部会员',
			},
			chooseTwo: {
				grade: '全部会员',
			},
			chooseThree: {
				grade: '全部会员',
			},
			chooseFour: {
				grade: '全部会员',
			},
			lineChart: '',
			ageChart: '',
			sexChart: '',
			sexBasicChart: '',
			sexCarChart: '',
			sexLineChart: '',
			conhabitChart: '',
			conhabitBasicChart: '',
			conhabitCarChart: '',
			conhabitLineChart: '',
			sickChart: '',
			eduChart: '',
			meChart: '',
			meBasicChart: '',
			meCarChart: '',
			meLineChart: '',
			light: '',
			normal: '',
			weight: '',
			fat: '',
			very_fat: '',
			all: '',
			xArr: [],
			yArr: [],
			count: 0,
			memberType: [],
		};
	},
	created() {
		this.getBasicBmi();
		this.getMemberType();
	},
	mounted() {
		// echarts实例化
		this.lineChart = echarts.init(this.$refs.basicData);
		// 会员年龄柱状图
		this.ageChart = echarts.init(this.$refs.ageData);
		// 会员性别各个等级饼图
		this.sexChart = echarts.init(this.$refs.sexData);
		this.sexBasicChart = echarts.init(this.$refs.sexBasicData);
		this.sexCarChart = echarts.init(this.$refs.sexCarData);
		this.sexLineChart = echarts.init(this.$refs.sexLineData);
		// 会员疾病信息柱状图
		this.sickChart = echarts.init(this.$refs.sickData);
		// 会员同住比例等级饼图
		this.conhabitChart = echarts.init(this.$refs.conhabitData);
		this.conhabitBasicChart = echarts.init(this.$refs.conhabitBasicData);
		this.conhabitCarChart = echarts.init(this.$refs.conhabitCarData);
		this.conhabitLineChart = echarts.init(this.$refs.conhabitLineData);
		// 会员文化程度柱状图
		this.eduChart = echarts.init(this.$refs.eduData);
		// 不同等级会员婚姻状况统计
		this.meChart = echarts.init(this.$refs.meData);
		this.meBasicChart = echarts.init(this.$refs.meBasicData);
		this.meCarChart = echarts.init(this.$refs.meCarData);
		this.meLineChart = echarts.init(this.$refs.meLineData);

		// 初始化实例
		let lineOptions = {
			title: {
				text: '',
				textStyle: {
					color: '#424e67',
					fontWeight: 'normal',
					fontSize: 14,
				},
				left: '45%',
				bottom: 40,
			},
			grid: {
				show: true,
				borderColor: '#e3e3e3',
				bottom: 80,
			},
			color: ['#f18985'],
			xAxis: {
				type: 'category',
				boundaryGap: false,
				axisLine: { onZero: false },
				data: [],
			},
			yAxis: [
				{
					name: '个',
					type: 'value',
				},
			],
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
					animation: false,
					label: {
						backgroundColor: '#505765',
					},
				},
			},
			dataZoom: [
				{
					show: true,
				},
				{
					type: 'inside',
				},
			],
			series: [
				{
					name: this.chooseOne + '建档量',
					type: 'line',
					symbolSize: 10,
					animation: false,
					symbol: 'circle',
					areaStyle: {
						normal: {
							color: 'transparent',
						},
					},
					lineStyle: {
						normal: {
							width: 3,
							color: '#f18985',
						},
					},
					data: [],
				},
			],
		};
		let ageOptions = {
			title: {
				text: '',
				textStyle: {
					color: '#424e67',
					fontWeight: 'normal',
					fontSize: 14,
				},
				left: '45%',
				bottom: 40,
			},
			grid: {
				show: true,
				borderColor: '#e3e3e3',
				bottom: 80,
			},
			color: ['#f1ce7e'],
			xAxis: {
				type: 'category',
				axisLine: { onZero: false },
				data: [
					'30岁及以下',
					'31-40岁',
					'41-50岁',
					'51-60岁',
					'61-70岁',
					'71-80岁',
					'81-90岁',
					'91-100岁',
					'101岁及以上',
				],
			},
			yAxis: [
				{
					type: 'value',
				},
			],
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow',
				},
			},
			series: [
				{
					name: '数据条数',
					type: 'bar',
					data: [],
				},
			],
		};
		let sexOptions = {
			tooltip: {
				trigger: 'item',
				formatter: '{b}: {c} ({d}%)',
			},
			title: {
				top: '40%',
				textAlign: 'center',
			},
			legend: {
				orient: 'vertical',
				right: 20,
				data: ['男', '女'],
			},
			color: ['#94e1f0', '#ff6c60'],
			series: [
				{
					type: 'pie',
					clockwise: false,
					startAngle: 60,
					radius: ['45%', '80%'],
					center: ['40%', '50%'],
					label: {
						normal: {
							show: true,
							position: 'inside',
							formatter: '{d}%',
						},
					},
					data: [
						{ value: 0, name: '男' },
						{ value: 0, name: '女' },
					],
				},
			],
		};
		let sexBasicOptions = {
			tooltip: {
				trigger: 'item',
				formatter: '{b}: {c} ({d}%)',
			},
			title: {
				top: '40%',
				textAlign: 'center',
			},
			legend: {
				orient: 'vertical',
				right: 20,
				data: ['男', '女'],
			},
			color: ['#94e1f0', '#ff6c60'],
			series: [
				{
					type: 'pie',
					clockwise: false,
					startAngle: 60,
					radius: ['45%', '80%'],
					center: ['40%', '50%'],
					label: {
						normal: {
							show: true,
							position: 'inside',
							formatter: '{d}%',
						},
					},
					data: [
						{ value: 0, name: '男' },
						{ value: 0, name: '女' },
					],
				},
			],
		};
		let sexCarOptions = {
			tooltip: {
				trigger: 'item',
				formatter: '{b}: {c} ({d}%)',
			},
			title: {
				top: '40%',
				textAlign: 'center',
			},
			legend: {
				orient: 'vertical',
				right: 20,
				data: ['男', '女'],
			},
			color: ['#94e1f0', '#ff6c60'],
			series: [
				{
					type: 'pie',
					clockwise: false,
					startAngle: 60,
					radius: ['45%', '80%'],
					center: ['40%', '50%'],
					label: {
						normal: {
							show: true,
							position: 'inside',
							formatter: '{d}%',
						},
					},
					data: [
						{ value: 0, name: '男' },
						{ value: 0, name: '女' },
					],
				},
			],
		};
		let sexLineOptions = {
			tooltip: {
				trigger: 'item',
				formatter: '{b}: {c} ({d}%)',
			},
			title: {
				top: '40%',
				textAlign: 'center',
			},
			legend: {
				orient: 'vertical',
				right: 20,
				data: ['男', '女'],
			},
			color: ['#94e1f0', '#ff6c60'],
			series: [
				{
					type: 'pie',
					clockwise: false,
					startAngle: 60,
					radius: ['45%', '80%'],
					center: ['40%', '50%'],
					label: {
						normal: {
							show: true,
							position: 'inside',
							formatter: '{d}%',
						},
					},
					data: [
						{ value: 0, name: '男' },
						{ value: 0, name: '女' },
					],
				},
			],
		};
		let sickOptions = {
			title: {
				text: '',
				textStyle: {
					color: '#424e67',
					fontWeight: 'normal',
					fontSize: 14,
				},
				left: '45%',
				bottom: 40,
			},
			grid: {
				show: true,
				borderColor: '#e3e3e3',
				bottom: 80,
			},
			color: ['#f1ce7e'],
			xAxis: {
				type: 'category',
				axisLine: { onZero: false },
				axisLabel: { interval: 0 },
				data: [],
			},
			yAxis: [
				{
					type: 'value',
				},
			],
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow',
				},
			},
			series: [
				{
					name: '数据条数',
					type: 'bar',
					data: [],
				},
			],
		};
		let conhabitOptions = {
			tooltip: {
				trigger: 'item',
				formatter: '{b}: {c} ({d}%)',
			},
			title: {
				top: '40%',
				textAlign: 'center',
			},
			legend: {
				orient: 'vertical',
				right: 20,
				data: ['不同住', '同住'],
			},
			color: ['#94e1f0', '#ff6c60'],
			series: [
				{
					type: 'pie',
					clockwise: false,
					startAngle: 60,
					radius: ['45%', '80%'],
					center: ['40%', '50%'],
					label: {
						normal: {
							show: true,
							position: 'inside',
							formatter: '{d}%',
						},
					},
					data: [
						{ value: 0, name: '不同住' },
						{ value: 0, name: '同住' },
					],
				},
			],
		};
		let conhabitBasicOptions = {
			tooltip: {
				trigger: 'item',
				formatter: '{b}: {c} ({d}%)',
			},
			title: {
				top: '40%',
				textAlign: 'center',
			},
			legend: {
				orient: 'vertical',
				right: 20,
				data: ['不同住', '同住'],
			},
			color: ['#94e1f0', '#ff6c60'],
			series: [
				{
					type: 'pie',
					clockwise: false,
					startAngle: 60,
					radius: ['45%', '80%'],
					center: ['40%', '50%'],
					label: {
						normal: {
							show: true,
							position: 'inside',
							formatter: '{d}%',
						},
					},
					data: [
						{ value: 0, name: '不同住' },
						{ value: 0, name: '同住' },
					],
				},
			],
		};
		let conhabitCarOptions = {
			tooltip: {
				trigger: 'item',
				formatter: '{b}: {c} ({d}%)',
			},
			title: {
				top: '40%',
				textAlign: 'center',
			},
			legend: {
				orient: 'vertical',
				right: 20,
				data: ['不同住', '同住'],
			},
			color: ['#94e1f0', '#ff6c60'],
			series: [
				{
					type: 'pie',
					clockwise: false,
					startAngle: 60,
					radius: ['45%', '80%'],
					center: ['40%', '50%'],
					label: {
						normal: {
							show: true,
							position: 'inside',
							formatter: '{d}%',
						},
					},
					data: [
						{ value: 0, name: '不同住' },
						{ value: 0, name: '同住' },
					],
				},
			],
		};
		let conhabitLineOptions = {
			tooltip: {
				trigger: 'item',
				formatter: '{b}: {c} ({d}%)',
			},
			title: {
				top: '40%',
				textAlign: 'center',
			},
			legend: {
				orient: 'vertical',
				right: 20,
				data: ['不同住', '同住'],
			},
			color: ['#94e1f0', '#ff6c60'],
			series: [
				{
					type: 'pie',
					clockwise: false,
					startAngle: 60,
					radius: ['45%', '80%'],
					center: ['40%', '50%'],
					label: {
						normal: {
							show: true,
							position: 'inside',
							formatter: '{d}%',
						},
					},
					data: [
						{ value: 0, name: '不同住' },
						{ value: 0, name: '同住' },
					],
				},
			],
		};
		let eduOptions = {
			title: {
				text: '',
				textStyle: {
					color: '#424e67',
					fontWeight: 'normal',
					fontSize: 14,
				},
				left: '45%',
				bottom: 40,
			},
			grid: {
				show: true,
				borderColor: '#e3e3e3',
				bottom: 80,
			},
			color: ['#f1ce7e'],
			xAxis: {
				type: 'category',
				axisLine: { onZero: false },
				data: [],
			},
			yAxis: [
				{
					type: 'value',
				},
			],
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow',
				},
			},
			series: [
				{
					name: '数据条数',
					type: 'bar',
					data: [],
				},
			],
		};
		let meOptions = {
			tooltip: {
				trigger: 'item',
				formatter: '{b}: {c} ({d}%)',
			},
			title: {
				top: '40%',
				textAlign: 'center',
			},
			legend: {
				orient: 'vertical',
				right: 20,
				data: ['已婚', '丧偶', '离婚', '未婚'],
			},
			color: ['#94e1f0', '#ff6c60', '#f1ce7e', '#66b0ea'],
			series: [
				{
					type: 'pie',
					clockwise: false,
					startAngle: 60,
					radius: ['45%', '80%'],
					center: ['40%', '50%'],
					label: {
						normal: {
							show: true,
							position: 'inside',
							formatter: '{d}%',
						},
					},
					data: [
						{ value: 0, name: '已婚' },
						{ value: 0, name: '丧偶' },
						{ value: 0, name: '离婚' },
						{ value: 0, name: '未婚' },
					],
				},
			],
		};
		let meBasicOptions = {
			tooltip: {
				trigger: 'item',
				formatter: '{b}: {c} ({d}%)',
			},
			title: {
				top: '40%',
				textAlign: 'center',
			},
			legend: {
				orient: 'vertical',
				right: 20,
				data: ['已婚', '丧偶', '离婚', '未婚'],
			},
			color: ['#94e1f0', '#ff6c60', '#f1ce7e', '#66b0ea'],
			series: [
				{
					type: 'pie',
					clockwise: false,
					startAngle: 0,
					radius: ['45%', '80%'],
					center: ['40%', '50%'],
					label: {
						normal: {
							show: true,
							position: 'inside',
							formatter: '{d}%',
						},
					},
					data: [
						{ value: 0, name: '已婚' },
						{ value: 0, name: '丧偶' },
						{ value: 0, name: '离婚' },
						{ value: 0, name: '未婚' },
					],
				},
			],
		};
		let meCarOptions = {
			tooltip: {
				trigger: 'item',
				formatter: '{b}: {c} ({d}%)',
			},
			title: {
				top: '40%',
				textAlign: 'center',
			},
			legend: {
				orient: 'vertical',
				right: 20,
				data: ['已婚', '丧偶', '离婚', '未婚'],
			},
			color: ['#94e1f0', '#ff6c60', '#f1ce7e', '#66b0ea'],
			series: [
				{
					type: 'pie',
					clockwise: false,
					startAngle: 60,
					radius: ['45%', '80%'],
					center: ['40%', '50%'],
					label: {
						normal: {
							show: true,
							position: 'inside',
							formatter: '{d}%',
						},
					},
					data: [
						{ value: 0, name: '已婚' },
						{ value: 0, name: '丧偶' },
						{ value: 0, name: '离婚' },
						{ value: 0, name: '未婚' },
					],
				},
			],
		};
		let meLineOptions = {
			tooltip: {
				trigger: 'item',
				formatter: '{b}: {c} ({d}%)',
			},
			title: {
				top: '40%',
				textAlign: 'center',
			},
			legend: {
				orient: 'vertical',
				right: 20,
				data: ['已婚', '丧偶', '离婚', '未婚'],
			},
			color: ['#94e1f0', '#ff6c60', '#f1ce7e', '#66b0ea'],
			series: [
				{
					type: 'pie',
					clockwise: false,
					startAngle: 60,
					radius: ['45%', '80%'],
					center: ['40%', '50%'],
					label: {
						normal: {
							show: true,
							position: 'inside',
							formatter: '{d}%',
						},
					},
					data: [
						{ value: 0, name: '已婚' },
						{ value: 0, name: '丧偶' },
						{ value: 0, name: '离婚' },
						{ value: 0, name: '未婚' },
					],
				},
			],
		};
		this.lineChart.setOption(lineOptions);
		this.lineChart.showLoading();
		this.ageChart.setOption(ageOptions);
		this.ageChart.setOption(ageOptions);
		this.sexChart.setOption(sexOptions);
		this.sexChart.showLoading();
		this.sexBasicChart.setOption(sexBasicOptions);
		this.sexBasicChart.showLoading();
		this.sexCarChart.setOption(sexCarOptions);
		this.sexCarChart.showLoading();
		this.sexLineChart.setOption(sexLineOptions);
		this.sexLineChart.showLoading();
		this.sickChart.setOption(sickOptions);
		this.sickChart.showLoading();

		this.conhabitChart.setOption(conhabitOptions);
		this.conhabitChart.showLoading();
		this.conhabitBasicChart.setOption(conhabitBasicOptions);
		this.conhabitBasicChart.showLoading();
		this.conhabitCarChart.setOption(conhabitCarOptions);
		this.conhabitCarChart.showLoading();
		this.conhabitLineChart.setOption(conhabitLineOptions);
		this.conhabitLineChart.showLoading();

		this.eduChart.setOption(eduOptions);
		this.eduChart.showLoading();

		this.meChart.setOption(meOptions);
		this.meChart.showLoading();
		this.meBasicChart.setOption(meBasicOptions);
		this.meBasicChart.showLoading();
		this.meCarChart.setOption(meCarOptions);
		this.meCarChart.showLoading();
		this.meLineChart.setOption(meLineOptions);
		this.meLineChart.showLoading();
		this.getChartDate();
		this.getChartAge();
		this.getChartBasic();
		this.getChartSick();
		this.getChartEducation();
	},
	methods: {
		getMemberType() {
			MemberService.getMemberType().then((data) => {
				this.memberType = data;
			});
		},
		changePerson(value) {
			this.chooseOne.grade = value;
			this.getChartDate();
		},
		getChartDate() {
			chartService.getChartDate(this.chooseOne).then((data) => {
				// this.count = data.total_count[0] ? data.total_count[0].count : 0
				this.xArr = [];
				this.yArr = [];
				data.date_total.forEach((item) => {
					this.xArr.push(item.create_time);
					this.yArr.push(item.count);
				});
				this.lineChart.hideLoading();
				this.lineChart.setOption({
					xAxis: {
						data: this.xArr,
					},
					series: [
						{
							name: '建档量',
							// 根据名字对应到相应的系列
							data: this.yArr,
						},
					],
				});
			});
		},
		changeAge(value) {
			this.chooseTwo.grade = value;
			this.getChartAge();
		},
		getChartAge() {
			chartService.getChartAge(this.chooseTwo).then((data) => {
				// this.count = data.total_count[0] ? data.total_count[0].count : 0
				this.yArr = [];
				data.total_count.forEach((item) => {
					this.yArr.push(item);
				});
				this.ageChart.hideLoading();
				this.ageChart.setOption({
					series: [
						{
							// 根据名字对应到相应的系列
							data: this.yArr,
						},
					],
				});
			});
		},
		changeSick(value) {
			this.chooseThree.grade = value;
			this.sickChart.showLoading();
			this.getChartSick();
		},
		getChartSick() {
			chartService.getChartSick(this.chooseThree).then((data) => {
				this.xArr = [];
				this.yArr = [];
				data.total_count.forEach((item) => {
					this.xArr.push(item.name);
					this.yArr.push(item.count);
				});
				this.sickChart.hideLoading();
				this.sickChart.setOption({
					xAxis: {
						data: this.xArr,
					},
					series: [
						{
							data: this.yArr,
						},
					],
				});
			});
		},
		getChartBasic() {
			chartService.getChartBasic().then((data) => {
				this.pieData = [];
				data.sex_grade.total_count.forEach((item) => {
					this.pieData.push({
						value: item.count,
						name: item.sex,
					});
				});
				if (this.pieData.length === 0) {
					this.pieData = [
						{ value: 0, name: '男' },
						{ value: 0, name: '女' },
					];
				}
				this.sexChart.hideLoading();
				this.sexChart.setOption({
					series: [
						{
							data: this.pieData,
						},
					],
				});
				this.pieBasicData = [];
				data.sex_grade.grade0.forEach((item) => {
					this.pieBasicData.push({
						value: item.count,
						name: item.sex,
					});
				});
				if (this.pieBasicData.length === 0) {
					this.pieBasicData = [
						{ value: 0, name: '男' },
						{ value: 0, name: '女' },
					];
				}
				this.sexBasicChart.hideLoading();
				this.sexBasicChart.setOption({
					series: [
						{
							data: this.pieBasicData,
						},
					],
				});

				this.pieCarData = [];
				data.sex_grade.grade1.forEach((item) => {
					this.pieCarData.push({
						value: item.count,
						name: item.sex,
					});
				});
				if (this.pieCarData.length === 0) {
					this.pieCarData = [
						{ value: 0, name: '男' },
						{ value: 0, name: '女' },
					];
				}
				this.sexCarChart.hideLoading();
				this.sexCarChart.setOption({
					series: [
						{
							data: this.pieCarData,
						},
					],
				});

				this.pieLineData = [];
				data.sex_grade.grade2.forEach((item) => {
					this.pieLineData.push({
						value: item.count,
						name: item.sex,
					});
				});
				if (this.pieLineData.length === 0) {
					this.pieLineData = [
						{ value: 0, name: '男' },
						{ value: 0, name: '女' },
					];
				}
				this.sexLineChart.hideLoading();
				this.sexLineChart.setOption({
					series: [
						{
							data: this.pieLineData,
						},
					],
				});
				// 同住不同住比例c
				this.cpieData = [];
				data.cohabit_grade.total_count.forEach((item) => {
					this.cpieData.push({
						value: item.count,
						name: item.con,
					});
				});
				if (this.cpieData.length === 0) {
					this.cpieData = [
						{ value: 0, name: '不同住' },
						{ value: 0, name: '同住' },
					];
				}
				this.conhabitChart.hideLoading();
				this.conhabitChart.setOption({
					series: [
						{
							data: this.cpieData,
						},
					],
				});
				this.cBasicData = [];
				data.cohabit_grade.grade0.forEach((item) => {
					this.cBasicData.push({
						value: item.count,
						name: item.sex,
					});
				});
				if (this.cBasicData.length === 0) {
					this.cBasicData = [
						{ value: 0, name: '不同住' },
						{ value: 0, name: '同住' },
					];
				}
				this.conhabitBasicChart.hideLoading();
				this.conhabitBasicChart.setOption({
					series: [
						{
							data: this.cBasicData,
						},
					],
				});

				this.cCarData = [];
				data.cohabit_grade.grade1.forEach((item) => {
					this.cCarData.push({
						value: item.count,
						name: item.sex,
					});
				});
				if (this.cCarData.length === 0) {
					this.cCarData = [
						{ value: 0, name: '不同住' },
						{ value: 0, name: '同住' },
					];
				}
				this.conhabitCarChart.hideLoading();
				this.conhabitCarChart.setOption({
					series: [
						{
							data: this.cCarData,
						},
					],
				});

				this.cLineData = [];
				data.cohabit_grade.grade2.forEach((item) => {
					this.cLineData.push({
						value: item.count,
						name: item.sex,
					});
				});
				if (this.cLineData.length === 0) {
					this.cLineData = [
						{ value: 0, name: '不同住' },
						{ value: 0, name: '同住' },
					];
				}
				this.conhabitLineChart.hideLoading();
				this.conhabitLineChart.setOption({
					series: [
						{
							data: this.cLineData,
						},
					],
				});
				//  婚姻比例c------------------
				this.mepieData = [];
				data.marriage_grade.total_count.forEach((item) => {
					this.mepieData.push({
						value: item.count,
						name: item.name,
					});
				});
				if (this.mepieData.length === 0) {
					this.mepieData = [
						{ value: 0, name: '已婚' },
						{ value: 0, name: '丧偶' },
						{ value: 0, name: '离婚' },
						{ value: 0, name: '未婚' },
					];
				}
				this.meChart.hideLoading();
				this.meChart.setOption({
					series: [
						{
							data: this.mepieData,
						},
					],
				});

				this.meBasicData = [];
				data.marriage_grade.grade0.forEach((item) => {
					this.meBasicData.push({
						value: item.count,
						name: item.name,
					});
				});
				if (this.meBasicData.length === 0) {
					this.meBasicData = [
						{ value: 0, name: '已婚' },
						{ value: 0, name: '丧偶' },
						{ value: 0, name: '离婚' },
						{ value: 0, name: '未婚' },
					];
				}
				this.meBasicChart.hideLoading();
				this.meBasicChart.setOption({
					series: [
						{
							data: this.meBasicData,
						},
					],
				});

				this.meCarData = [];
				data.marriage_grade.grade1.forEach((item) => {
					this.meCarData.push({
						value: item.count,
						name: item.name,
					});
				});
				if (this.meCarData.length === 0) {
					this.meCarData = [
						{ value: 0, name: '已婚' },
						{ value: 0, name: '丧偶' },
						{ value: 0, name: '离婚' },
						{ value: 0, name: '未婚' },
					];
				}
				this.meCarChart.hideLoading();
				this.meCarChart.setOption({
					series: [
						{
							data: this.meCarData,
						},
					],
				});

				this.meLineData = [];
				data.marriage_grade.grade2.forEach((item) => {
					this.meLineData.push({
						value: item.count,
						name: item.name,
					});
				});
				if (this.meLineData.length === 0) {
					this.meLineData = [
						{ value: 0, name: '已婚' },
						{ value: 0, name: '丧偶' },
						{ value: 0, name: '离婚' },
						{ value: 0, name: '未婚' },
					];
				}
				this.meLineChart.hideLoading();
				this.meLineChart.setOption({
					series: [
						{
							data: this.meLineData,
						},
					],
				});
			});
		},
		changeEdu(value) {
			this.chooseFour.grade = value;
			this.eduChart.showLoading();
			this.getChartEducation();
		},
		getChartEducation() {
			chartService.getChartEducation(this.chooseFour).then((data) => {
				this.xArr = [];
				this.yArr = [];
				data.total_count.forEach((item) => {
					this.xArr.push(item.name);
					this.yArr.push(item.count);
				});
				this.eduChart.hideLoading();
				this.eduChart.setOption({
					xAxis: {
						data: this.xArr,
					},
					series: [
						{
							data: this.yArr,
						},
					],
				});
			});
		},
		getBasicBmi() {
			chartService.getBasicBmi().then((data) => {
				this.all = data.all;
				this.light = data.light;
				this.normal = data.normal;
				this.weight = data.weight;
				this.fat = data.fat;
				this.very_fat = data.very_fat;
			});
		},
	},
};
</script>

<style lang="css" scoped>
.chart-basic-container .big-percent {
  font-size: 20px;
  line-height: 30px;
}
.chart-basic-container .bmi-num {
  color: #999;
}
.chart-basic-container .bmi-title {
  display: inline-block;
  padding: 4px 13px;
  border-radius: 15px;
  color: #fff;
  margin: 10px 10px 20px 0;
}
</style>
